import _isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import { injectIntl } from 'react-intl'

import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import ControlPointDuplicateOutlinedIcon from '@material-ui/icons/ControlPointDuplicateOutlined'
import DeleteIcon from '@material-ui/icons/Delete'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { ColorPicker } from 'components/common'

import { getGpsSignals, getDm1Signals } from './config'
import messages from './messages'
import * as ReportConstants from '../../constants'

const styles = {
  colorPicker: {
    width: 16,
    height: 16,
    display: 'inline-block',
    borderRadius: 10,
    verticalAlign: 'middle',
    margin: 'auto'
  },
  groupLabel: {
    color: 'black',
    fontWeight: 'bold'
  },
  metric: {
    borderLeft: '4px solid #484848',
    borderRadius: 0,
    margin: '6px 0px 6px -6px'
  },
  tag: {
    height: '26px'
  },
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  },
  button: {
    margin: 0
  },
  sensor: {
    backgroundColor: '#FFF4E5'
  },
  duplicatedSignalTooltip: {
    backgroundColor: '#191919',
    fontSize: 12,
    width: 200
  },
  arrow: {
    color: '#212121'
  }
}

class Metric extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
  }

  getSensorsOptions = () => {
    const { assets, metric, pageType } = this.props

    const assetsById = R.indexBy(R.prop('id'), assets)
    const assetId = metric.asset
    const asset = assetsById?.[assetId]

    if (!_isEmpty(asset?.deviceConfiguration)) {
      const mappedSensorMap = asset.deviceConfiguration.sensorsMap
        .filter(sensor => sensor.frequency !== 0)
        .map(sensor => ({
          label: sensor.name,
          value: sensor.name,
          unit: sensor.unit,
          signalId: sensor.signalId,
          multiplier: sensor.multiplier,
          offset: sensor.offset,
          divider: sensor.divider,
          signed: sensor.signed,
          lengthOfBits: sensor.lengthOfBits,
          signalType: this.formatMessage(messages.canBusSignals)
        }))

      const mappedMfio = asset.deviceConfiguration.MFIO.map(sensor => ({
        label: sensor.name,
        value: sensor.name,
        unit: sensor.unit,
        signalId: sensor.signalId,
        multiplier: sensor.multiplier,
        offset: sensor.offset,
        divider: sensor.divider,
        signed: sensor.signed,
        lengthOfBits: sensor.lengthOfBits,
        isMFIO: true,
        signalType: this.formatMessage(messages.mfioSignals)
      }))

      const mappedGps = getGpsSignals(asset, pageType)
      const mappedDm1 = getDm1Signals(asset, pageType)

      return [...mappedGps, ...mappedMfio, ...mappedSensorMap, ...mappedDm1]
    } else if (
      assetId &&
      pageType === ReportConstants.PAGE_TYPES.TABLE_PAGE_TYPE &&
      metric.tableType === ReportConstants.TABLE_TYPES.DM1_LAST_KNOWN_VALUES_TYPE
    ) {
      const mappedDm1 = getDm1Signals(asset, pageType)
      return [...mappedDm1]
    }

    return []
  }

  getAdaptedSelectedSensorsIds = (selectedSensorsIds, pageType, tableType) => {
    const isChartType = pageType === ReportConstants.PAGE_TYPES.CHART_PAGE_TYPE
    const isMapTypePage = pageType === ReportConstants.PAGE_TYPES.MAP_PAGE_TYPE
    const isTableTypePage = pageType === ReportConstants.PAGE_TYPES.TABLE_PAGE_TYPE
    const isTableTypeAndDM1LastKnownValues =
      isTableTypePage && tableType === ReportConstants.TABLE_TYPES.DM1_LAST_KNOWN_VALUES_TYPE
    const isTableTypeAndDM1HistoricValues =
      isTableTypePage && tableType === ReportConstants.TABLE_TYPES.DM1_HISTORIC_VALUES_TYPE
    const isTableTypeAndSignalHistoricValues =
      isTableTypePage && tableType === ReportConstants.TABLE_TYPES.SIGNAL_LAST_KNOWN_VALUES_TYPE

    if (isChartType || isTableTypeAndSignalHistoricValues) {
      return selectedSensorsIds
    } else if (isMapTypePage) {
      return [ReportConstants.GPS_SENSOR_IDS.POSITION_MAP]
    } else if (isTableTypeAndDM1LastKnownValues) {
      return [ReportConstants.DM1_SENSOR_IDS.DM1_LAST_KNOWN_VALUES]
    } else if (isTableTypeAndDM1HistoricValues) {
      return [ReportConstants.DM1_SENSOR_IDS.DM1_HISTORIC_VALUES]
    } else {
      return []
    }
  }

  handleAssetSelectChange = (event, newValue) => {
    const { onAssetUpdate } = this.props

    onAssetUpdate(newValue?.id)
  }

  handleSignalsSelectChange = (event, newValue) => {
    const { onSignalUpdate } = this.props
    const sensorsOptions = this.getSensorsOptions()

    onSignalUpdate(
      newValue.map(item => item.signalId),
      sensorsOptions
    )
  }

  handleDeleteAsset = () => {
    const { isOnlyOne, onDeleteMetric } = this.props
    if (!isOnlyOne) {
      onDeleteMetric()
    }
  }

  handleDeleteSignal = signalIndex => {
    const { onDeleteSignal } = this.props
    onDeleteSignal(signalIndex)
  }

  handleDuplicateSignal = signalIndex => {
    const { onDuplicateSignal } = this.props
    onDuplicateSignal(signalIndex)
  }

  handleColorChanged = (signalIndex, value) => {
    const { onSignalColorChanged } = this.props
    onSignalColorChanged(signalIndex, value)
  }

  handleAggregationChanged = (event, signalIndex) => {
    const { onSignalAggregationChanged } = this.props
    onSignalAggregationChanged(signalIndex, event.target.value)
  }

  handleLinestyleChanged = (event, signalIndex) => {
    const { onSignalLinestyleChanged } = this.props
    onSignalLinestyleChanged(signalIndex, event.target.value)
  }

  renderSelectMenuWithLabels = (itemList = []) => {
    const { pageType } = this.props

    const isMapTypePage = pageType === ReportConstants.PAGE_TYPES.MAP_PAGE_TYPE

    return itemList.map((elem, i) => {
      return (
        <MenuItem
          key={i}
          disabled={elem.value === ReportConstants.GPS_SENSOR_IDS.POSITION_MAP ? false : isMapTypePage}
          value={elem.value}
        >
          {elem.label}
        </MenuItem>
      )
    })
  }

  render() {
    const {
      assets,
      classes,
      isOnlyOne,
      metric: { asset, sensors, selectedSensorsIds },
      pageType,
      selectedDevices,
      tableType
    } = this.props

    const isMapTypePage = pageType === ReportConstants.PAGE_TYPES.MAP_PAGE_TYPE
    const isTableTypePage = pageType === ReportConstants.PAGE_TYPES.TABLE_PAGE_TYPE
    const isTableTypeAndDM1LastKnownValues =
      isTableTypePage && tableType === ReportConstants.TABLE_TYPES.DM1_LAST_KNOWN_VALUES_TYPE
    const isTableTypeAndDM1HistoricValues =
      isTableTypePage && tableType === ReportConstants.TABLE_TYPES.DM1_HISTORIC_VALUES_TYPE
    const isSignalSelectorDisabled =
      isMapTypePage || isTableTypeAndDM1LastKnownValues || isTableTypeAndDM1HistoricValues

    const filterOptions = options =>
      isTableTypeAndDM1LastKnownValues ? options.filter(device => device.deviceType === 'CS500') : options

    const adaptedSelectedSensorsIds = this.getAdaptedSelectedSensorsIds(selectedSensorsIds, pageType, tableType)

    const sensorsOptions = this.getSensorsOptions()

    const aggregations = [
      { value: 'average', label: this.formatMessage(messages.average) },
      { value: 'min', label: this.formatMessage(messages.minimum) },
      { value: 'max', label: this.formatMessage(messages.maximum) }
    ]

    const lineStyles = [
      { value: 'line', label: this.formatMessage(messages.line) },
      { value: 'column', label: this.formatMessage(messages.column) },
      { value: 'point', label: this.formatMessage(messages.points) }
    ]

    return (
      <Grid
        container
        spacing={3}
        style={{ borderLeft: '4px solid #484848', borderRadius: 0, margin: '6px 0px 6px -6px' }}
      >
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Autocomplete
                classes={{ groupLabel: classes.groupLabel, tag: classes.tag }}
                disableClearable
                filterOptions={filterOptions}
                getOptionDisabled={option =>
                  (isTableTypeAndDM1LastKnownValues || isMapTypePage) &&
                  selectedDevices.find(device => device === option.id)
                }
                getOptionLabel={option => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={this.handleAssetSelectChange}
                options={assets}
                renderInput={params => <TextField {...params} label={this.formatMessage(messages.machine)} />}
                value={assets.find(item => item.id === asset) || null}
              />
            </Grid>
            <Grid container item spacing={3} xs={8}>
              <Grid item xs={10}>
                <Autocomplete
                  classes={{ groupLabel: classes.groupLabel, tag: classes.tag }}
                  disableClearable
                  disableCloseOnSelect
                  disabled={isSignalSelectorDisabled}
                  getOptionLabel={option => option.label || ''}
                  getOptionSelected={(option, value) => option.signalId === value.signalId}
                  groupBy={option => option.signalType}
                  multiple
                  onChange={this.handleSignalsSelectChange}
                  options={sensorsOptions}
                  renderInput={params => <TextField {...params} label={this.formatMessage(messages.signals)} />}
                  value={sensorsOptions.filter(item => adaptedSelectedSensorsIds.includes(item.signalId))}
                />
              </Grid>
              <Grid className='text-right' item xs={2}>
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  placement='top'
                  title={this.formatMessage(messages.deleteMachine)}
                >
                  <div>
                    <IconButton
                      classes={{ root: classes.button }}
                      disabled={isOnlyOne}
                      onClick={this.handleDeleteAsset}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {isMapTypePage || isTableTypeAndDM1LastKnownValues || isTableTypeAndDM1HistoricValues
                ? []
                : sensors.map((sensor, sensorIndex) => {
                  return (
                    <Grid key={sensorIndex} className={sensor.duplicated ? classes.sensor : ''} container spacing={3}>
                      <Grid container item xs={4}>
                        <Grid className='text-right' container item xs={2}>
                          {sensor.duplicated ? (
                            <Tooltip
                              arrow
                              classes={{ tooltip: classes.duplicatedSignalTooltip, arrow: classes.arrow }}
                              placement='top'
                              title={this.formatMessage(messages.thisSignalIsNotDisplayed)}
                            >
                              <Icon
                                className='zmdi zmdi-alert-triangle'
                                style={{ color: '#f0ad4e', margin: 'auto' }}
                              />
                            </Tooltip>
                          ) : (
                            <ColorPicker
                              className={classes.colorPicker}
                              onChange={value => {
                                this.handleColorChanged(sensorIndex, value)
                              }}
                              value={sensor.lineColor}
                            />
                          )}
                        </Grid>
                        <Grid container item xs={10}>
                          <FormControl fullWidth>
                            <InputLabel>{this.formatMessage(messages.signal)}</InputLabel>
                            <Select disabled value={sensor.name}>
                              {this.renderSelectMenuWithLabels(sensorsOptions)}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid container item spacing={3} xs={8}>
                        <Grid item xs={5}>
                          <FormControl fullWidth>
                            <InputLabel>{this.formatMessage(messages.aggregation)}</InputLabel>
                            <Select
                              onChange={event => this.handleAggregationChanged(event, sensorIndex)}
                              value={sensor.aggregationType}
                            >
                              {this.renderSelectMenuWithLabels(aggregations)}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                          <FormControl fullWidth>
                            <InputLabel>{this.formatMessage(messages.lineStyle)}</InputLabel>
                            <Select
                              onChange={event => this.handleLinestyleChanged(event, sensorIndex)}
                              value={sensor.lineStyle}
                            >
                              {this.renderSelectMenuWithLabels(lineStyles)}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid className='text-right' item xs={2}>
                          <Tooltip
                            classes={{ tooltip: classes.tooltip }}
                            placement='top'
                            title={this.formatMessage(messages.duplicateSignal)}
                          >
                            <IconButton
                              classes={{ root: classes.button }}
                              onClick={() => {
                                this.handleDuplicateSignal(sensorIndex)
                              }}
                            >
                              <ControlPointDuplicateOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            classes={{ tooltip: classes.tooltip }}
                            placement='top'
                            title={this.formatMessage(messages.deleteSignal)}
                          >
                            <IconButton
                              classes={{ root: classes.button }}
                              onClick={() => {
                                this.handleDeleteSignal(sensorIndex)
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

Metric.propTypes = {
  assets: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  isOnlyOne: PropTypes.bool.isRequired,
  metric: PropTypes.object.isRequired,
  onAssetUpdate: PropTypes.func.isRequired,
  onDeleteMetric: PropTypes.func.isRequired,
  onDeleteSignal: PropTypes.func.isRequired,
  onDuplicateSignal: PropTypes.func.isRequired,
  onSignalAggregationChanged: PropTypes.func.isRequired,
  onSignalColorChanged: PropTypes.func.isRequired,
  onSignalLinestyleChanged: PropTypes.func.isRequired,
  onSignalUpdate: PropTypes.func.isRequired,
  pageType: PropTypes.string.isRequired,
  selectedDevices: PropTypes.array.isRequired,
  tableType: PropTypes.string.isRequired
}

export default injectIntl(withStyles(styles)(Metric))
