import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Alert from '@material-ui/lab/Alert'

import { BasicChart } from './BasicChart'
import messages from './messages'
import { ChartToolbar } from './toolbar'

const Chart = ({
  title,
  onSerieVisibilityChange,
  additionalControls,
  predefinedZoomLevels,
  selectedZoomLevel,
  onZoomSelected,
  navigator,
  toggleNavigatorVisibility,
  lowerXAxisLimit,
  upperXAxisLimit,
  setXAxisLimits,
  config,
  isChartLoading,
  areMetricsWithDuplicatedSensors,
  intl,
  ...other
}) => {
  const controlProps = {
    title,
    loading: isChartLoading,
    onSerieVisibilityChange,
    additionalControls,
    predefinedZoomLevels,
    selectedZoomLevel,
    onZoomSelected,
    navigator,
    toggleNavigatorVisibility,
    lowerXAxisLimit,
    upperXAxisLimit,
    setXAxisLimits
  }
  let seriesForVisibilityPanel = null
  if (onSerieVisibilityChange) {
    seriesForVisibilityPanel = config.series.map(v => ({
      id: v.id,
      name: v.name,
      isVisible: v.visible,
      color: v.color
    }))
  }

  return (
    <div>
      {areMetricsWithDuplicatedSensors && (
        <div className='col-md-12' style={{ marginTop: '1em', padding: '0px 2px' }}>
          <Alert severity='warning'>{intl.formatMessage(messages.duplicatedSignalsAreNotDisplayed)}</Alert>
        </div>
      )}
      <div className='col-md-12' style={{ marginTop: '1em', padding: 0 }}>
        <BasicChart config={config} isChartLoading={isChartLoading} {...other} />
      </div>
      <div className='col-md-12' style={{ marginBottom: 20, padding: '0px 2px' }}>
        <ChartToolbar series={seriesForVisibilityPanel} {...controlProps} />
      </div>
    </div>
  )
}

Chart.propTypes = {
  additionalControls: PropTypes.arrayOf(PropTypes.node),
  areMetricsWithDuplicatedSensors: PropTypes.bool.isRequired,
  config: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  isChartLoading: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  lowerXAxisLimit: PropTypes.number,
  navigator: PropTypes.bool,
  onZoomSelected: PropTypes.func,
  predefinedZoomLevels: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      label: PropTypes.string,
      value: PropTypes.number
    })
  ),
  selectedZoomLevel: PropTypes.number,
  setXAxisLimits: PropTypes.func,
  showToolbar: PropTypes.bool,
  title: PropTypes.string,
  toggleNavigatorVisibility: PropTypes.func,
  upperXAxisLimit: PropTypes.number
}

export default injectIntl(Chart)
