import moment from 'moment'
import React from 'react'

import MenuItem from 'material-ui/MenuItem'

const momentDate = data => moment(data).format('L')

const showActionMenuItems = actions => {
  if (actions !== undefined) {
    return actions.map(action => (
      <MenuItem key={'actionFor' + action.hashId} primaryText={action.description} value={action} />
    ))
  } else {
    return <div />
  }
}

const getSensorUnit = sensorName => {
  const units = {
    altitude: 'm',
    latitude: 'º',
    longitude: 'º',
    speed: 'km/h',
    heading: 'º'
  }

  return units[sensorName.toLowerCase()]
}

const getMetricsWithDuplicatedSensorsMarked = metrics =>
  metrics.map(metric => {
    const sensorsWithDuplicatesMarked = metric.sensors.reduce((acc, cur) => {
      const { signalId, valueType, aggregationType, bucket } = cur
      const previousSensorWithSameValues = acc.find(
        sensor =>
          sensor.signalId === signalId &&
          sensor.valueType === valueType &&
          sensor.aggregationType === aggregationType &&
          sensor.bucket === bucket
      )
      const duplicated = typeof previousSensorWithSameValues === 'undefined' ? false : true
      return [...acc, { ...cur, duplicated }]
    }, [])
    return { ...metric, sensors: sensorsWithDuplicatesMarked }
  })

export { getSensorUnit, momentDate, showActionMenuItems, getMetricsWithDuplicatedSensorsMarked }
