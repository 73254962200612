import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Grid from '@material-ui/core/Grid'

import DateTimeRangeSelector from 'components/DateTimeRangeSelector'

import messages from './messages'

const SearchControl = ({
  intl,
  isChartLoading,
  maxRequestDate,
  maxResponseDate,
  minRequestDate,
  minResponseDate,
  onDateTimeMaxChange,
  onDateTimeMinChange,
  requestStatus,
  setSearchRange,
  showWarning = false
}) => {
  const { formatMessage } = intl
  const getViewWarning = () => {
    let warning = ''
    if (requestStatus === 'pending') {
      warning = formatMessage(messages.firstWarning)
    } else {
      if (minRequestDate && maxResponseDate) {
        const minDiff = moment.duration(minRequestDate).subtract(minResponseDate)
        const maxDiff = moment.duration(maxRequestDate).subtract(maxResponseDate)

        if (minDiff.asMinutes() >= 1 || minDiff.asMinutes() <= -1) {
          warning = formatMessage(messages.secondWarning) + minDiff.humanize()
        }
        if (maxDiff.asMinutes() >= 1 || maxDiff.asMinutes() <= -1) {
          if (warning === '') {
            warning = formatMessage(messages.thirdWarning) + maxDiff.humanize()
          } else {
            warning += ' ' + formatMessage(messages.fourthWarning) + ' ' + maxDiff.humanize()
          }
        }
      }
    }

    if (warning === '') return null
    return (
      <div className='row'>
        <p className='col-xs-12 col-md-3 col-md-push-8 text-center'>({warning})</p>
      </div>
    )
  }
  return (
    <div style={{ padding: '0px 12px 12px 0px' }}>
      <Grid container spacing={3} style={{ display: 'flex', alignItems: 'center' }}>
        <div className='col-xs-12 col-md-7' style={{ marginTop: '20px' }}>
          <DateTimeRangeSelector
            loading={isChartLoading}
            maxDate={maxRequestDate}
            minDate={minRequestDate}
            onMaxChange={onDateTimeMaxChange}
            onMinChange={onDateTimeMinChange}
            onSearch={setSearchRange}
          />
        </div>
      </Grid>
      {showWarning && getViewWarning()}
    </div>
  )
}

export const propTypes = {
  intl: PropTypes.object.isRequired,
  isChartLoading: PropTypes.bool,
  maxRequestDate: PropTypes.number,
  maxResponseDate: PropTypes.number,
  minRequestDate: PropTypes.number,
  minResponseDate: PropTypes.number,
  onDateTimeMaxChange: PropTypes.func.isRequired,
  onDateTimeMinChange: PropTypes.func.isRequired,
  requestStatus: PropTypes.string,
  setSearchRange: PropTypes.func.isRequired,
  showWarning: PropTypes.bool
}

SearchControl.propTypes = propTypes

SearchControl.defaultProps = {
  isChartLoading: false,
  maxRequestDate: undefined,
  maxResponseDate: undefined,
  minRequestDate: undefined,
  minResponseDate: undefined,
  requestStatus: undefined,
  showWarning: false
}

export default injectIntl(SearchControl)
