import { defineMessages } from 'react-intl'

const namespace = 'Chart'

const messages = defineMessages({
  duplicatedSignalsAreNotDisplayed: {
    id: `${namespace}.duplicatedSignalsAreNotDisplayed`,
    description: 'Duplicated signals are not displayed',
    defaultMessage: 'Duplicated signals are not displayed'
  }
})

export default messages
