import { defineMessages } from 'react-intl'

const namespace = 'DateTimeRangeSelector'

const messages = defineMessages({
  from: {
    id: `${namespace}.from`,
    description: 'DateTimeRangeSelector from label',
    defaultMessage: 'From'
  },
  to: {
    id: `${namespace}.to`,
    description: 'DateTimeRangeSelector to label',
    defaultMessage: 'To'
  },
  time: {
    id: `${namespace}.time`,
    description: 'Time hint text part',
    defaultMessage: '{ floatinglabeltext } time'
  },
  ok: {
    id: `${namespace}.ok`,
    description: 'Ok button label',
    defaultMessage: 'OK'
  },
  cancel: {
    id: `${namespace}.cancel`,
    description: 'Cancel button label',
    defaultMessage: 'cancel'
  },
  generateChart: {
    id: `${namespace}.generateChart`,
    description: 'Generate chart',
    defaultMessage: 'Generate chart'
  }
})

export default messages
