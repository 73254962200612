import { defineMessages } from 'react-intl'

const namespace = 'NewReport'

const messages = defineMessages({
  error400Message: {
    id: `${namespace}.error400Message`,
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error403Message: {
    id: `${namespace}.error403Message`,
    description: 'Error 403 message',
    defaultMessage: 'Access denied'
  },
  error404Message: {
    id: `${namespace}.error404Message`,
    description: 'Error 404 message',
    defaultMessage: 'API url not found.'
  },
  error406Message: {
    id: `${namespace}.error406Message`,
    description: 'Error 406 message',
    defaultMessage: 'Not acceptable'
  },
  errorUndefinedTitle: {
    id: `${namespace}.errorUndefinedTitle`,
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: `${namespace}.errorUndefinedMessage`,
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  title: {
    id: `${namespace}.title`,
    description: 'New Report title',
    defaultMessage: 'New report'
  },
  cancel: {
    id: `${namespace}.cancel`,
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  thisFieldIsRequired: {
    id: `${namespace}.thisFieldIsRequired`,
    description: 'This field is required text',
    defaultMessage: 'This field is required.'
  },
  selectAParentReport: {
    id: `${namespace}.selectAParentReport`,
    description: 'Select a parent report message',
    defaultMessage: 'Select a parent report.'
  },
  error409Message: {
    id: `${namespace}.error409Message`,
    description: 'Error 409 message',
    defaultMessage: 'Data integrity violation: Report name it`s already in use'
  },
  error415Message: {
    id: `${namespace}.error415Message`,
    description: 'Error 415 message',
    defaultMessage: 'Unsupported media type'
  },
  error422Message: {
    id: `${namespace}.error422Message`,
    description: 'Error 422 message',
    defaultMessage: 'Validation failed'
  },
  name: {
    id: `${namespace}.name`,
    description: 'Name floating label text',
    defaultMessage: 'Name'
  },
  selectParentReport: {
    id: `${namespace}.selectParentReport`,
    description: 'Select parent report label text',
    defaultMessage: 'Select parent report'
  },
  save: {
    id: `${namespace}.save`,
    description: 'Save button label',
    defaultMessage: 'Save'
  },
  error: {
    id: `${namespace}.error`,
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  error401Message: {
    id: `${namespace}.error401Message`,
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  },
  noMachinesOrMetrics: {
    id: `${namespace}.noMachinesOrMetrics`,
    description: 'No machines or metrics available',
    defaultMessage: 'No machines or metrics available'
  },
  noMachineExists404: {
    id: `${namespace}.noMachineExists404`,
    description: 'No machine exists',
    defaultMessage: 'No machine exists.'
  },
  users: {
    id: `${namespace}.users`,
    description: 'Users',
    defaultMessage: 'Users'
  },
  reportSettings: {
    id: `${namespace}.reportSettings`,
    description: 'Report Settings',
    defaultMessage: 'Report Settings'
  },
  reportName: {
    id: `${namespace}.reportName`,
    description: 'Report name',
    defaultMessage: 'Report name'
  },
  bucketSize: {
    id: `${namespace}.bucketSize`,
    description: 'Bucket size',
    defaultMessage: 'Bucket size'
  },
  status: {
    id: `${namespace}.status`,
    description: 'Status',
    defaultMessage: 'Status'
  },
  scheduleSettings: {
    id: `${namespace}.scheduleSettings`,
    description: 'Schedule settings',
    defaultMessage: 'Schedule settings'
  },
  initialDateOfExecution: {
    id: `${namespace}.initialDateOfExecution`,
    description: 'Initial date of execution',
    defaultMessage: 'Initial date of execution'
  },
  reportFrequency: {
    id: `${namespace}.reportFrequency`,
    description: 'Report frequency',
    defaultMessage: 'Report frequency'
  },
  timezone: {
    id: `${namespace}.timezone`,
    description: 'Timezone',
    defaultMessage: 'Timezone'
  },
  metrics: {
    id: `${namespace}.metrics`,
    description: 'Metrics',
    defaultMessage: 'Metrics'
  },
  checkEmailFormat: {
    id: `${namespace}.checkEmailFormat`,
    description: 'Check Email Format',
    defaultMessage: 'Check email format'
  },
  addMachine: {
    id: `${namespace}.addMachine`,
    description: 'Add a machine',
    defaultMessage: 'Add a machine'
  },
  machine: {
    id: `${namespace}.machine`,
    description: 'Machine',
    defaultMessage: 'Machine'
  },
  deletePage: {
    id: `${namespace}.deletePage`,
    description: 'Remove Page Tooltip',
    defaultMessage: 'Remove page'
  },
  duplicatePage: {
    id: `${namespace}.duplicatePage`,
    description: 'Duplicate Page  tooltip',
    defaultMessage: 'Duplicate page'
  },
  collapse: {
    id: `${namespace}.collapse`,
    description: 'Collapse button label',
    defaultMessage: 'Collapse'
  },
  expand: {
    id: `${namespace}.expand`,
    description: 'Expand button label',
    defaultMessage: 'Expand'
  },
  signal: {
    id: `${namespace}.signal`,
    description: 'Signal',
    defaultMessage: 'Signal'
  },
  aggregation: {
    id: `${namespace}.aggregation`,
    description: 'Aggregation',
    defaultMessage: 'Aggregation'
  },
  bucket: {
    id: `${namespace}.bucket`,
    description: 'Bucket Size',
    defaultMessage: 'Bucket size'
  },
  lineStyle: {
    id: `${namespace}.lineStyle`,
    description: 'Line Style',
    defaultMessage: 'Line style'
  },
  displayInfo: {
    id: `${namespace}.displayInfo`,
    description: 'Display of Information',
    defaultMessage: 'Display of information'
  },
  deleteSignal: {
    id: `${namespace}.deleteSignal`,
    description: 'Remove Signal Tooltip',
    defaultMessage: 'Remove signal'
  },
  duplicateSignal: {
    id: `${namespace}.duplicateSignal`,
    description: 'Duplicate Signal  tooltip',
    defaultMessage: 'Duplicate signal'
  },
  deleteMachine: {
    id: `${namespace}.deleteMachine`,
    description: 'Remove machine tooltip',
    defaultMessage: 'Remove machine'
  },
  addPage: {
    id: `${namespace}.addPage`,
    description: 'Add page button label',
    defaultMessage: 'New page'
  },
  minute: {
    id: `${namespace}.minute`,
    description: 'Minute',
    defaultMessage: 'Minute'
  },
  hour: {
    id: `${namespace}.hour`,
    description: 'Hour',
    defaultMessage: 'Hour'
  },
  day: {
    id: `${namespace}.day`,
    description: 'Day',
    defaultMessage: 'Day'
  },
  daily: {
    id: `${namespace}.daily`,
    description: 'Daily',
    defaultMessage: 'Daily'
  },
  weekly: {
    id: `${namespace}.weekly`,
    description: 'Weekly',
    defaultMessage: 'Weekly'
  },
  monthly: {
    id: `${namespace}.monthly`,
    description: 'Monthly',
    defaultMessage: 'Monthly'
  },
  active: {
    id: `${namespace}.active`,
    description: 'Active',
    defaultMessage: 'Active'
  },
  page: {
    id: `${namespace}.page`,
    description: 'Page',
    defaultMessage: 'Page'
  },
  average: {
    id: `${namespace}.average`,
    description: 'Average',
    defaultMessage: 'Average'
  },
  minimum: {
    id: `${namespace}.minimum`,
    description: 'Minimum',
    defaultMessage: 'Minimum'
  },
  maximum: {
    id: `${namespace}.maximum`,
    description: 'Maximum',
    defaultMessage: 'Maximum'
  },
  line: {
    id: `${namespace}.line`,
    description: 'Line',
    defaultMessage: 'Line'
  },
  column: {
    id: `${namespace}.column`,
    description: 'Column',
    defaultMessage: 'Column'
  },
  points: {
    id: `${namespace}.points`,
    description: 'Points',
    defaultMessage: 'Points'
  },
  signals: {
    id: `${namespace}.signals`,
    description: 'Signals',
    defaultMessage: 'Signals'
  },
  gpsSignals: {
    id: `${namespace}.gpsSignals`,
    description: 'GPS signals',
    defaultMessage: 'GPS signals'
  },
  dm1Signals: {
    id: `${namespace}.dm1Signals`,
    description: 'DM1 signals',
    defaultMessage: 'DM1 signals'
  },
  mfioSignals: {
    id: `${namespace}.mfioSignals`,
    description: 'MFIO signals',
    defaultMessage: 'MFIO signals'
  },
  canBusSignals: {
    id: `${namespace}.canBusSignals`,
    description: 'CAN Bus signals',
    defaultMessage: 'CAN Bus signals'
  },
  pageType: {
    id: `${namespace}.pageType`,
    description: 'Page type',
    defaultMessage: 'Page type'
  },
  chartPage: {
    id: `${namespace}.chartPage`,
    description: 'Chart page type',
    defaultMessage: 'Chart page'
  },
  mapPage: {
    id: `${namespace}.mapPage`,
    description: 'Map page type',
    defaultMessage: 'Map page'
  },
  tablePage: {
    id: `${namespace}.tablePage`,
    description: 'Table page type',
    defaultMessage: 'Table page'
  },
  tableType: {
    id: `${namespace}.tableType`,
    description: 'Table page type',
    defaultMessage: 'Table type'
  },
  dm1LastKnownValuesTable: {
    id: `${namespace}.DM1LastKnownValuesTable`,
    description: 'DM1 last known values table type',
    defaultMessage: 'DM1 last known values'
  },
  dm1HistoricValuesTable: {
    id: `${namespace}.dm1HistoricValuesTable`,
    description: 'DM1 historic values table type',
    defaultMessage: 'DM1 historic values'
  },
  signalLastKnownValuesTable: {
    id: `${namespace}.signalLastKnownValuesTable`,
    description: 'Historic table type',
    defaultMessage: 'Signal last known values'
  },
  allMachines: {
    id: `${namespace}.allMachines`,
    description: 'All machines',
    defaultMessage: 'All machines'
  },
  allMachinesSelected: {
    id: `${namespace}.allMachinesSelected`,
    description: 'All machines selected',
    defaultMessage: 'All machines selected*'
  },
  incompleteFieldTitle: {
    id: `${namespace}.incompleteFieldTitle`,
    description: 'Incomplete required fields title',
    defaultMessage: 'Incomplete required fields:'
  },
  incompleteFieldText: {
    id: `${namespace}.incompleteFieldText`,
    description: 'Incomplete required fields text',
    defaultMessage: 'Please, review the report and fill all the required fields.'
  },
  thisSignalIsNotDisplayed: {
    id: `${namespace}.thisSignalIsNotDisplayed`,
    description: 'This signal is not displayed',
    defaultMessage:
      'This signal is not displayed in the chart because it is a duplicate of another \
    signal with the same parameters (value type, aggregation, and bucket size).'
  }
})

export default messages
