import PropTypes from 'prop-types'
import React from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import NormalBody from './NormalBody'
import ToolbarGroup from './ToolbarGroup'
import ZoomSelector from './ZoomSelector'

const ChartToolbar = ({ title, loading = false, ...other }) => (
  <Toolbar style={{ backgroundColor: '#F4F4F4', paddingRight: 12 }}>
    {title && (
      <ToolbarGroup firstChild style={{ margin: '0 20px' }}>
        <Typography color='inherit' noWrap variant='h6'>
          {title}
        </Typography>
      </ToolbarGroup>
    )}
    {loading && <CircularProgress />}
    {!loading && (
      <React.Fragment>
        <ZoomSelector {...other} />
        <NormalBody {...other} />
      </React.Fragment>
    )}
  </Toolbar>
)

ChartToolbar.propTypes = {
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired
}

export { ChartToolbar }
